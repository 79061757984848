import React , {useEffect, useState, useContext}from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import axiosInstance from "../../utils/axios";
import { Nav, Navbar, Button} from "react-bootstrap";
import { Container } from "react-bootstrap";
import StyledNetworkNavbar from '../styling/StyledNetworkNavbar';
import SearchBar from '../components/SearchBar';
import ContextApi from '../../ContextApi';
import LoadingSpinner from './LoadingSpinner';
import { motion } from "framer-motion";



const NetworkNavbar = () => {
    const { auth, user, currentChannel, setCurrentChannel, currentTab, setCurrentTab} = useContext(ContextApi);

    const [themeNavBar, setCurrentNavBar] = useState("#802595");
    const [buttonColor, setButtonColor] = useState("#FAA61A");
    const [selectedButtoncolor, setSelectedButtonColor] = useState("#ED1668")
    const [loading, setLoading] = useState([])
    

    useEffect(() => {

        const setTheme = async () => {
            
            setLoading(true)

            const api = `/siteTheme`;
            const resp = await axiosInstance
                .get(api)
                .then(res => {
                    let resData = res.data[0];

                    
                    //setCurrentNavBar(resData.navBarColor);
                    //setButtonColor(resData.buttonColor);
                    //setSelectedButtonColor(resData.selectedButtonColor);

                    
                })

        }

        setTheme()
        setLoading(false);
        

    },[]);



    const updateChannelSelected = (channel) =>{
        setCurrentTab();
        setCurrentChannel(channel);

    }

    return (
        <StyledNetworkNavbar>
            <LoadingSpinner loading={loading} />
            {/* backgroundImage used to be backgroundColor:#colorcode */}
            {/*backgroundImage:'url(images/PR22_HC_SummerNights_PRSlide_NavigationBar_KO_V1.jpg)', paddingBottom:'5px'*/}
            {/* //original blue */}
            {/* <div className='networkNav-bg' style={{backgroundColor:"#802595"}}> */}

            {/* loveuary */}
            <div className='networkNav-bg' style={{backgroundColor:"#802595"}}>
                <Container>
                    <Navbar bg="#4A317C" expand="lg">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <div className='network_container'>
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-channel" onClick={() => updateChannelSelected("hallmark-channel")}>
                                            <section className="keyart_section">
                                                <div className="img-hover-zoom img-hover-zoom--xyz">
                                                    <img className='network-logo hallmarkChannel' alt='hallmark channel logo' src='/images/network-logo/HC-white.png' width='90px' />
                                                </div>
                                            </section>
                                        </Link>
                                    </Nav> */}
                                    <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-channel" onClick={() => updateChannelSelected("hallmark-channel")}>
                                            
                                            <Button style={currentChannel == "hallmark-channel" ? { //Selected selectedButtoncolor
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:selectedButtoncolor,
                                                                                                    //borderColor: selectedButtoncolor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    //backgroundColor: selectedButtoncolor,
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595',
                                                                                                    outline:'none'
                                                                                                    //Change this background URL for the new current page gif   bottom can be non-repeat, repeat, bottom, top...
                                                                                                    //background:'url(/images/FebHeart.gif) 20%'
                                                                                                    //borderBottomColor:selectedButtoncolor,
                                                                                                    //borderBottomWidth:'5px'
                                                                                                    } : 
                                                                                                    {
                                                                                                    //Default buttonColor
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:buttonColor,
                                                                                                    //borderColor:buttonColor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595',
                                                                                                    borderRadius:'10px',
                                                                                                    outline:'none'
                                                                                                    }}>
                                            <section className="keyart_section">
                                                <div className="">
                                                    <img className='network-logo hallmarkChannel' alt='hallmark channel logo' src={currentChannel == "hallmark-channel" ? '/images/network-logo/HC_Loveuary2025.png' : '/images/network-logo/HC_White.png'} width='125px' />
                                                    
                                                </div>
                                            </section>
                                            </Button>
                                        </Link>
                                    </Nav>
                                    <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-plus" onClick={() => updateChannelSelected("hallmark-plus")}>
                                            <Button style={currentChannel == "hallmark-plus" ? { //Selected
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:selectedButtoncolor,
                                                                                                    //borderColor: selectedButtoncolor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    //backgroundColor: selectedButtoncolor,
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595'
                                                                                                    //Change this background URL for the new current page gif   bottom can be non-repeat, repeat, bottom, top...
                                                                                                    //background:'url(/images/FebHeart.gif) 20%'
                                                                                                    //borderBottomColor:selectedButtoncolor,
                                                                                                    //borderBottomWidth:'5px'
                                                                                                    } : 
                                                                                                    {
                                                                                                    //Default buttonColor
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:buttonColor,
                                                                                                    //borderColor:buttonColor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595'
                                                                                                
                                                                                                    }}>
                                            <section className="keyart_section">
                                                <div className="">
                                                    <img className='network-logo hallmarkChannel' alt='hallmark channel logo' src={currentChannel == "hallmark-plus" ? '/images/network-logo/HP_Loveuary2025.png' : '/images/network-logo/HP_White.png'} width='133px' />
                                                    
                                                </div>
                                            </section>
                                            </Button>
                                        </Link>
                                    </Nav>
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-movies-mysteries" onClick={() => updateChannelSelected("hallmark-movies-mysteries")}>
                                            <section className="keyart_section">
                                                <div className="img-hover-zoom img-hover-zoom--xyz">
                                                    <img className='network-logo moviesMysteries' alt='hallmark channel logo' src='/images/network-logo/HMM_Logo_white.png' width='95px' />
                                                </div>
                                            </section>
                                        </Link>
                                    </Nav> */}
                                    <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-mystery" onClick={() => updateChannelSelected("hallmark-mystery")}>
                                            <Button style={currentChannel == "hallmark-mystery" ? { //Selected
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:selectedButtoncolor,
                                                                                                    //borderColor: selectedButtoncolor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    //backgroundColor: selectedButtoncolor,
                                                                                                    //Change this background URL for the new current page gif   bottom can be non-repeat, repeat, bottom, top...
                                                                                                    //background:'url(/images/FebHeart.gif) 20%'
                                                                                                    //borderBottomColor:selectedButtoncolor,
                                                                                                    //borderBottomWidth:'5px'
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595'
                                                                                                    } : 
                                                                                                    {
                                                                                                    //Default buttonColor
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:buttonColor,
                                                                                                    //borderColor:buttonColor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595',
                                                                                                    borderRadius:'10px'
                                                                                                    }}>
                                            <section className="keyart_section">
                                                <div className="">
                                                    <img className='network-logo hallmarkChannel' alt='hallmark channel logo' src={currentChannel == "hallmark-mystery" ? '/images/network-logo/HM_Loveuary2025.png' : '/images/network-logo/HM_White.png' } width='120px' />
                                                     
                                                </div>
                                            </section>
                                            </Button>
                                        </Link>
                                    </Nav>

                                   
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-movies-now" onClick={() => updateChannelSelected("hallmark-movies-now")}>
                                            <section className="keyart_section">
                                                <div className="img-hover-zoom img-hover-zoom--xyz">
                                                    <img className='network-logo moviesNow' alt='hallmark channel logo' src='/images/network-logo/HMN_Logo_white_alt.png' width='85px' />
                                                </div>
                                            </section>
                                        </Link>
                                    </Nav> */}
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-movies-now" onClick={() => updateChannelSelected("hallmark-movies-now")}>
                                            <Button style={currentChannel == "hallmark-movies-now" ? { //Selected
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:selectedButtoncolor,
                                                                                                    //borderColor: selectedButtoncolor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    //backgroundColor: selectedButtoncolor,
                                                                                                    //Change this background URL for the new current page gif   bottom can be non-repeat, repeat, bottom, top...
                                                                                                    //background:'url(/images/FebHeart.gif) 20%'
                                                                                                    //borderBottomColor:selectedButtoncolor,
                                                                                                    //borderBottomWidth:'5px'
                                                                                                    backgroundColor:'#511759',
                                                                                                    borderColor:'#511759'
                                                                                                    } : 
                                                                                                    {
                                                                                                    //Default buttonColor
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:buttonColor,
                                                                                                    //borderColor:buttonColor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    backgroundColor:'#511759',
                                                                                                    borderColor:'#511759'
                                                                                                    }}>
                                            <section className="keyart_section">
                                                <div className="">
                                                    <img className='network-logo hallmarkChannel' alt='hallmark channel logo' src={currentChannel == "hallmark-movies-now" ? '/images/network-logo/HMN_LightBlue.png' : '/images/network-logo/HMN_White.png' } width='118px' />
                                                    
                                                </div>
                                            </section>
                                            </Button>
                                        </Link>
                                    </Nav> */}
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-drama" onClick={() => updateChannelSelected("hallmark-drama")}>
                                            <section className="keyart_section">
                                                <div className="img-hover-zoom img-hover-zoom--xyz">
                                                    <img className='network-logo hallmarkDrama' alt='hallmark channel logo' src='/images/network-logo/HD_logo-white.png' width='85px' />
                                                </div>
                                            </section>
                                        </Link>
                                    </Nav> */}
                                    <Nav className='navbar-light, navbar-nav, nav-link'>
                                        <Link to="/hallmark-family" onClick={() => updateChannelSelected("hallmark-family")}>
                                            <Button style={currentChannel == "hallmark-drama" ? { //Selected
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:selectedButtoncolor,
                                                                                                    //borderColor: selectedButtoncolor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    //backgroundColor: selectedButtoncolor,
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595'
                                                                                                    //Change this background URL for the new current page gif   bottom can be non-repeat, repeat, bottom, top...
                                                                                                    //background:'url(/images/FebHeart.gif) 20%'
                                                                                                    //borderBottomColor:selectedButtoncolor,
                                                                                                    //borderBottomWidth:'5px'
                                                                                                    } : 
                                                                                                    {
                                                                                                    //Default buttonColor
                                                                                                    color:'#fff',
                                                                                                    //backgroundColor:buttonColor,
                                                                                                    //borderColor:buttonColor, 
                                                                                                    //boxShadow:'1px 3px 7px black',
                                                                                                    borderRadius:'10px',
                                                                                                    backgroundColor:'#802595',
                                                                                                    borderColor:'#802595'
                                                                                                
                                                                                                    }}>
                                            <section className="keyart_section">
                                                <div className="">
                                                    <img className='network-logo hallmarkChannel' alt='hallmark channel logo' src={currentChannel == "hallmark-family" ? '/images/network-logo/HF_Loveuary2025.png' : '/images/network-logo/HF_White.png'} width='110px' style={{paddingTop:'5px'}}/>
                                                    
                                                </div>
                                            </section>
                                            </Button>
                                        </Link>
                                    </Nav>
                                    
                                    

                                </div>
                                <div className='account_container'>
                                    {auth ? <Nav className='navbar-light, navbar-nav, nav-link'><Link to="/logout">Log Out</Link></Nav> : <Nav className='navbar-light, navbar-nav, nav-link'><Link to="/login">Login</Link></Nav> }
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'><Link to="/login">Login</Link></Nav> */}
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'><Link to="/register">Register</Link></Nav>  */}
                                    {/* <Nav className='navbar-light, navbar-nav, nav-link'><Link to="/feedback">Feedback</Link></Nav>
                                    <Nav className='navbar-light, navbar-nav, nav-link'><Link to="/tech-support">Tech Support</Link></Nav> */}
                                    <a className='navbar-light, navbar-nav, nav-link' href="mailto:pressfeedback@crownmedia.com">Tech Support</a>
                                    <a className='navbar-light, navbar-nav, nav-link' href="mailto:pressfeedback@crownmedia.com">Feedback</a>
                                    <SearchBar />

                                </div>
                            </Nav>

                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </div>
        </StyledNetworkNavbar>
    );
};


const currentChannelStyle = {
    color:'#fff',
    backgroundColor:'#ce970f',
    borderColor:'#ce970f', boxShadow:'1px 3px 7px black',
    borderRadius:'10px',
    //Change this background URL for the new current page gif   bottom can be non-repeat, repeat, bottom, top...
    //background:'url(/images/FebHeart.gif) 20%'
    backgroundColor:'#ce970f'
}

const defaultChannelStyle = {
    color:'#fff',
    backgroundColor:'#e8b934',
    borderColor:'#e8b934', 
    boxShadow:'1px 3px 7px black',
    borderRadius:'10px',
}



export default NetworkNavbar;