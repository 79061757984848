import styled from 'styled-components';
import media from '../styling/media';

const StyledNetworkNavbar = styled.div`
    .networkNav-bg {
        background: #4A317C;
    }

    @media (max-width: 992px) {

        .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-toggler, a {
            
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-align: center;
        
            &:hover {
                /* color: #F1C400; */
                color: #f4849f;
                text-decoration: none;
                
            }
    
            &:active, &.active {
                color: #f4849f;
                text-decoration: none;      
                
            }
    
        }

        .navbar-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-align: center;
        }
        }

    .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-toggler, a {
        color: rgba(255, 255, 255, 0.85);

        &:hover {
            color: #f4849f;
            text-decoration: none;
        }

        &:active, &.active {
            color: #f4849f;
            text-decoration: none;
        }

    }

    .mr-auto {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0;
    }

    .network_container, .account_container {
        display: flex;
        flex-direction: row;
    }

    .network-logo {
        padding: 0 3px ;
    }

    .container { 
        padding: 0;
    }

    .navbar {
        padding: 0;
    }

    //image zoom
.keyart_section {
  width: 100%;
  margin: 0 auto;
}

.img-hover-zoom {
    height: 35px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */

    /* [3] Finally, transforming the image when container gets hovered */
    &:hover img {
      transform: scale(1.07);
    }
  }

  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    transition: transform 0.5s ease;
  }

  //image zoom

    
`
export default StyledNetworkNavbar;