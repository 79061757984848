import React, { useEffect } from 'react'
import { Container, Button, Row } from 'react-bootstrap';
import { motion } from "framer-motion";
import SignUp from './SignUp';
import { size } from 'lodash';
import { text } from '@fortawesome/fontawesome-svg-core';

const WelcomeLeftSide = ({ SignUp }) => {



  return (
    <div className='WelcomeGraphic' style={styles.container}>
      <motion.div className="box"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 3,
          delay: 0.7,
          ease: [0, 0.71, 0.2, 1.01]
        }}>
        <div style={{ display: 'flex' }}>
          <img src='images\HallmarkMedia_Logo_White.png' style={{ width: '300px', display: 'flex', alignItems: 'center', margin: 'auto', paddingTop: '300px' }}>
          </img>

        </div>
      </motion.div>
      <motion.div className="box"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.5,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01]
        }}>
        <h1 style={{ display: 'flex', justifyContent: 'center', color: 'white', paddingTop: '40px', fontSize: '30px' }}>Welcome to Hallmark Media Press Site</h1>
        <p style={{ textAlign: 'center', fontSize: '13px', color: 'white', marginTop: '200px' }}>A note to our viewers: Access to the Hallmark Media press site is reserved for members of the media.</p>
        <p style={{ textAlign: 'center', fontSize: '13px', color: 'white' }}>If you have questions, please e-mail <a href="mailto:viewers@hallmarkmedia.com? subject=subject text" style={{ color: 'white' }}> viewers@hallmarkmedia.com</a>.</p>
        <p style={{ textAlign: 'center', fontSize: '13px', color: 'white' }}> For information on all our programming -- including cast details, synopses, video, photos and air dates -- please visit</p>
        <p style={{ textAlign: 'center', fontSize: '13px', color: 'white' }}> <a style={{ color: 'white' }} href='https://www.hallmarkchannel.com/'> www.hallmarkchannel.com </a>, <a style={{ color: 'white' }} href="https://www.hallmarkplus.com/"> https://www.hallmarkplus.com</a>, <a style={{ color: 'white' }} href="https://www.hallmarkmystery.com/"> www.hallmarkmystery.com</a> and <a style={{ color: 'white' }} href="https://www.hallmarkfamily.com/">www.hallmarkfamily.com</a>.</p>
        {/* <p>A note to our viewers: Access to the Hallmark Media press site is reserved for members of the media. If you have questions, please e-mail viewers@hallmarkmedia.com. For information on all our programming -- including cast details, synopses, video, photos and air dates -- please visit www.hallmarkchannel.com, www.hallmarkmoviesandmysteries.com and www.hallmarkdrama.com.</p> */}
        {/* <p style={{display:'flex', justifyContent:'center', fontSize:'15px', color:'white', marginTop:'100px'}}>A note to our viewers: Access to the Hallmark Media press site is reserved for members of the media</p>
        <p style={{display:'flex', justifyContent:'center', fontSize:'15px', color:'white'}}>If you have questions, please e-mail viewers@hallmarkmedia.com.</p>
        <p style={{display:'flex', justifyContent:'center', fontSize:'12px', color:'white'}}>For information on all our programming -- including cast details, synopses, video, photos and air dates -- please visit www.hallmarkchannel.com, www.hallmarkmoviesandmysteries.com and www.hallmarkdrama.com.</p> */}
      </motion.div>
    </div>

  )
}

export default WelcomeLeftSide;


const styles = {
  container: {
    //background: 'rgb(74,49,124)',
    //background: 'linear-gradient(35deg, rgba(74,49,124,1) 53%, rgba(129,96,196,1) 100%)',
    // background: 'rgb(251,233,193)',
    // background: 'linear-gradient(27deg, rgba(251,233,193,1) 0%, rgba(69,166,173,1) 100%)',

    //loveuary
    background: 'rgb(246, 174, 189)',
    background: 'linear-gradient(27deg, rgba(246, 174, 189, 1) 0%, rgba(142, 62, 180, 1) 100%)',
    height: '1450px'
  },


  signIn: {
    background: 'rgb(74,49,124)',
    background: 'linear-gradient(4deg, rgba(74,49,124,1) 0%, rgba(118,78,196,1) 100%)',
    height: '1500px'

  },

  signUp: {
    background: 'rgb(74,49,124)',
    background: 'linear-gradient(4deg, rgba(74,49,124,1) 0%, rgba(118,78,196,1) 100%)',
    height: '1000px'
  }
}



